import {
  formatClinicSnippet,
  type ClinicListElementSource,
  type ClinicListElement,
} from '~/typeEntities/stores/Clinic'
const argumentToReturnArray = (
  clinics: ClinicListElementSource[],
  sortByTotalWeight = true
) => {
  let retClinics = clinics.map(formatClinicSnippet)
  if (sortByTotalWeight) {
    retClinics = retClinics.sort((a, b) => {
      return b.totalWeight - a.totalWeight
    })
  }
  return retClinics
}
export const useClinicListStore = defineStore('clinic-list-store', () => {
  const clinics = ref([] as ClinicListElement[])
  const currentPage = ref(1)
  const pageCount = ref(1)
  const count = ref(0)

  const currentMenuPage = ref(1)
  const currentMedicalCasePage = ref(1)

  const setPageData = (
    currentPageArg: number,
    pageCountArg: number,
    countArg: number
  ) => {
    currentPage.value = currentPageArg
    pageCount.value = pageCountArg
    count.value = countArg
  }
  const setClinics = (
    clinicsArg: ClinicListElementSource[],
    sortByTotalWeight = true
  ) => {
    clinics.value = argumentToReturnArray(clinicsArg, sortByTotalWeight)
  }
  const addClinics = (
    clinicsArg: ClinicListElementSource[],
    sortByTotalWeight = true
  ) => {
    clinics.value = clinics.value.concat(
      argumentToReturnArray(clinicsArg, sortByTotalWeight)
    )
  }
  const incrementMenuPage = () => {
    if (!isMoreMenuLocally.value) return

    currentMenuPage.value++
  }
  const incrementMedicalCasePage = () => {
    if (!isMoreMedicalCaseLocally.value) return

    currentMedicalCasePage.value++
  }

  const hasNextPage = computed(() => {
    return currentPage.value < pageCount.value
  })
  const getMenuListSource = computed(() => {
    return clinics.value.flatMap((clinic) => {
      const clinicPhotoUrl =
        (clinic.photos.length > 0
          ? clinic.photos[0].imageUrl
          : '/noimage.jpg') || '/noimage.jpg'
      const clinicReportsAverage =
        clinic.reportsCount === 0
          ? 0
          : clinic.totalReportRating / clinic.reportsCount
      return clinic.menus.map((menu) => {
        return {
          ...menu,
          clinicId: clinic.id,
          clinicPhotoUrl,
          clinicName: clinic.name,
          clinicReportsCount: clinic.reportsCount,
          clinicReportsAverage,
        }
      })
    })
  })
  const getMenuCount = computed(() => {
    return currentMenuPage.value * 20
  })
  const getTotalMenuCount = computed(() => {
    return getMenuListSource.value.length
  })
  const isMoreMenuLocally = computed(() => {
    return getMenuCount.value < getTotalMenuCount.value
  })
  const isMoreMenuButtonShowable = computed(() => {
    return isMoreMenuLocally.value || hasNextPage.value
  })
  const getMenuList = computed(() => {
    const searchStore = useSearchStore()
    const menus = (() => {
      if (searchStore.getOrder.key === 'minPrice') {
        const directionNumber = searchStore.getOrder.order === 'desc' ? 1 : -1
        return getMenuListSource.value.sort((a, b) => {
          // nullを最後にする
          if (a.lowestPrice === null && b.lowestPrice === null) {
            return 0
          } else if (a.lowestPrice === null) {
            return 1
          } else if (b.lowestPrice === null) {
            return -1
          } else {
            return (a.lowestPrice - b.lowestPrice) * directionNumber
          }
        })
      } else {
        return getMenuListSource.value
      }
    })()
    return menus.slice(0, getMenuCount.value)
  })
  const getMedicalCaseListSource = computed(() => {
    return clinics.value.flatMap((clinic) => {
      return clinic.medicalCases.map((medicalCase) => {
        return {
          ...medicalCase,
          clinicId: clinic.id,
          clinicName: clinic.name,
        }
      })
    })
  })
  const getMedicalCaseCount = computed(() => {
    return currentMedicalCasePage.value * 20
  })
  const getTotalMedicalCaseCount = computed(() => {
    return getMedicalCaseListSource.value.length
  })
  const isMoreMedicalCaseLocally = computed(() => {
    return getMedicalCaseCount.value < getTotalMedicalCaseCount.value
  })
  const isMoreMedicalCaseButtonShowable = computed(() => {
    return isMoreMedicalCaseLocally.value || hasNextPage.value
  })
  const getMedicalCaseList = computed(() => {
    return getMedicalCaseListSource.value.slice(0, getMedicalCaseCount.value)
  })

  return {
    clinics,
    currentPage,
    currentMenuPage,
    pageCount,
    count,
    setPageData,
    setClinics,
    addClinics,
    hasNextPage,
    getMenuList,
    incrementMenuPage,
    isMoreMenuLocally,
    isMoreMenuButtonShowable,
    getMedicalCaseList,
    getTotalMedicalCaseCount,
    incrementMedicalCasePage,
    isMoreMedicalCaseLocally,
    isMoreMedicalCaseButtonShowable,
  }
})
